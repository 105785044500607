import { ACTIONS } from "../actions/Permissions";
import createReducer from "../utils/createReducer";

const initialState = {
  permissionsLoaded: false,
  metadataLoaded: false,
  metadata: null,
  userPermissions: null,
  processing: true,
};

const permissionsReducerLiteral = {
  [ACTIONS.SET_PERMISSIONS_METADATA]: (state, action) => ({
    ...state,
    metadataLoaded: true,
    metadata: { ...action.payload },
  }),
  [ACTIONS.SET_USER_PERMISSIONS]: (state, action) => ({
    ...state,
    permissionsLoaded: true,
    userPermissions: action.payload,
  }),
  [ACTIONS.SET_USER_ME_DATA]: (state, action) => ({
    ...state,
    userMe: action.payload,
  }),
  [ACTIONS.CLEAR_USER_PERMISSIONS]: (state) => ({
    ...state,
    userPermissions: null,
    userMe: null,
  }),
  [ACTIONS.SET_USER_PERMISSION_LOADING]: (state, action) => ({
    ...state,
    processing: action.payload,
  }),
};

export const PermissionsReducer = createReducer(permissionsReducerLiteral, initialState);
