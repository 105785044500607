import React from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
import { localStorageKeys } from "../../config/localStorage";
import { Redirect, Route } from "react-router-dom";

const RedirectToApolloRoute = ({ children, ...rest }) => {
  const { value } = useLocalStorage(localStorageKeys.DEFAULT_UI);
  return value !== "v5" ? <Redirect to="/apollo" /> : <Route {...rest}>{children}</Route>;
};

export default RedirectToApolloRoute;
