import { fab } from "@fortawesome/free-brands-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import {
  faArrowsAltV as farArrowsAltV,
  faBraille as farBraille,
  faCaretSquareRight as farCaretSquareRight,
  faCircle as farCircle,
  faClock as farClock,
  faCloud as farCloud,
  faDotCircle as farDotCircle,
  faEllipsisV as farEllipsisV,
  faFilter as farFilter,
  faKeySkeleton as farKeySkeleton,
  faLevelDown as farLevelDown,
  faLevelUp as farLevelUp,
  faLevelUpAlt as farLevelUpAlt,
  faLongArrowAltDown as farLongArrowAltDown,
  faLongArrowAltRight as farLongArrowAltRight,
  faLongArrowRight as farLongArrowRight,
  faMapMarker as farMapMarker,
  faMapMarkerSlash as farMapMarkerSlash,
  faPencil as farPencil,
  faRouteInterstate as farRouteInterstate,
  faSlidersH as farSlidersH,
  faSlidersHSquare as farSlidersHSquare,
  faStopwatch as farStopwatch,
  faStopCircle as farStopCircle,
  faThumbtack as farThumbtack,
  faTimes as farTimes,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faAlbum as fasAlbum,
  faAlbumCollection as fasAlbumCollection,
  faArrowAltCircleUp as fasArrowAltCircleUp,
  faArrowAltFromLeft as fasArrowAltFromLeft,
  faArrowAltSquareUp as fasArrowAltSquareUp,
  faArrowAltToRight as fasArrowAltToRight,
  faArrowCircleRight as fasArrowCircleRight,
  faArrowCircleUp as fasArrowCircleUp,
  faArrowSquareUp as fasArrowSquareUp,
  faArrowToRight as fasArrowToRight,
  faBadgeCheck as fasBadgeCheck,
  faBan as fasBan,
  faBolt as fasBolt,
  faBriefcase as fasBriefcase,
  faBringForward as fasBringForward,
  faBullhorn as fasBullhorn,
  faCaretDown as fasCaretDown,
  faCaretRight as fasCaretRight,
  faCaretUp as fasCaretUp,
  faCheck as fasCheck,
  faCheckCircle as fasCheckCircle,
  faChevronSquareDown as fasChevronSquareDown,
  faClipboardCheck as fasClipboardCheck,
  faClock as fasClock,
  faClone as fasClone,
  faCube as fasCube,
  faCubes as fasCubes,
  faExclamationCircle as fasExclamationCircle,
  faExclamationTriangle as fasExclamationTriangle,
  faEye as fasEye,
  faEyeSlash as fasEyeSlash,
  faFilter as fasFilter,
  faFireExtinguisher as fasFireExtinguisher,
  faFlag as fasFlag,
  faFolder as fasFolder,
  faGlobe as fasGlobe,
  faGripHorizontal as fasGripHorizontal,
  faHeart as fasHeart,
  faHeartbeat as fasHeartbeat,
  faIndent as fasIndent,
  faInfoCircle as fasInfoCircle,
  faKey as fasKey,
  faLayerGroup as fasLayerGroup,
  faLayerPlus as fasLayerPlus,
  faLevelDownAlt as fasLevelDownAlt,
  faLevelUpAlt as fasLevelUpAlt,
  faLongArrowAltDown as fasLongArrowAltDown,
  faLongArrowAltRight as fasLongArrowAltRight,
  faMapMarker as fasMapMarker,
  faMapMarkerAlt as fasMapMarkerAlt,
  faMapMarkerMinus as fasMapMarkerMinus,
  faMapMarkerPlus as fasMapMarkerPlus,
  faMapMarkerSlash as fasMapMarkerSlash,
  faMapMarkerTimes as fasMapMarkerTimes,
  faMinusCircle as fasMinusCircle,
  faMinusSquare as fasMinusSquare,
  faObjectGroup as fasObjectGroup,
  faPauseCircle as fasPauseCircle,
  faPen as fasPen,
  faPencilAlt as fasPencilAlt,
  faPersonCarry as fasPersonCarry,
  faPlus as fasPlus,
  faPlusCircle as fasPlusCircle,
  faPlusSquare as fasPlusSquare,
  faQuestionCircle as fasQuestionCircle,
  faRedoAlt as fasRedoAlt,
  faRouteInterstate as fasRouteInterstate,
  faRunning as fasRunning,
  faSendBackward as fasSendBackward,
  faSensor as fasSensor,
  faShieldAlt as fasShieldAlt,
  faShieldCheck as fasShieldCheck,
  faSignInAlt as fasSignInAlt,
  faSignOutAlt as fasSignOutAlt,
  faSkull as fasSkull,
  faSlidersH as fasSlidersH,
  faStar as fasStar,
  faStarExclamation as fasStarExclamation,
  faStars as fasStars,
  faStopCircle as fasStopCircle,
  faSuitcase as fasSuitcase,
  faThumbtack as fasThumbtack,
  faTimes as fasTimes,
  faTimesCircle as fasTimesCircle,
  faTimesSquare as fasTimesSquare,
  faUniversalAccess as fasUniversalAccess,
  faUser as fasUser,
  faUserCrown as fasUserCrown,
  faUsers as fasUsers,
  faWrench as fasWrench,
} from "@fortawesome/pro-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(
  // Brands
  fab,
  // Light icons
  fal,
  // Regular icons
  farArrowsAltV,
  farBraille,
  farCaretSquareRight,
  farCircle,
  farClock,
  farCloud,
  farDotCircle,
  farEllipsisV,
  farFilter,
  farKeySkeleton,
  farLevelDown,
  farLevelUp,
  farLevelUpAlt,
  farLongArrowAltDown,
  farLongArrowAltRight,
  farLongArrowRight,
  farMapMarker,
  farMapMarkerSlash,
  farPencil,
  farRouteInterstate,
  farSlidersH,
  farSlidersHSquare,
  farStopCircle,
  farStopwatch,
  farThumbtack,
  farTimes,
  // Solid icons
  fasAlbum,
  fasAlbumCollection,
  fasArrowAltCircleUp,
  fasArrowAltFromLeft,
  fasArrowAltSquareUp,
  fasArrowAltToRight,
  fasArrowCircleRight,
  fasArrowCircleUp,
  fasArrowSquareUp,
  fasArrowToRight,
  fasBadgeCheck,
  fasBan,
  fasBolt,
  fasBriefcase,
  fasBringForward,
  fasBullhorn,
  fasCaretDown,
  fasCaretRight,
  fasCaretUp,
  fasCheck,
  fasCheckCircle,
  fasChevronSquareDown,
  fasClipboardCheck,
  fasClock,
  fasClone,
  fasCube,
  fasCubes,
  fasExclamationCircle,
  fasExclamationTriangle,
  fasEye,
  fasEyeSlash,
  fasFilter,
  fasFireExtinguisher,
  fasFlag,
  fasFolder,
  fasGlobe,
  fasGripHorizontal,
  fasHeart,
  fasHeartbeat,
  fasIndent,
  fasInfoCircle,
  fasKey,
  fasLayerGroup,
  fasLayerPlus,
  fasLevelDownAlt,
  fasLevelUpAlt,
  fasLongArrowAltDown,
  fasLongArrowAltRight,
  fasMapMarker,
  fasMapMarkerAlt,
  fasMapMarkerMinus,
  fasMapMarkerPlus,
  fasMapMarkerSlash,
  fasMapMarkerTimes,
  fasMinusCircle,
  fasMinusSquare,
  fasObjectGroup,
  fasPauseCircle,
  fasPen,
  fasPencilAlt,
  fasPersonCarry,
  fasPlus,
  fasPlusCircle,
  fasPlusSquare,
  fasQuestionCircle,
  fasRedoAlt,
  fasRouteInterstate,
  fasRunning,
  fasSendBackward,
  fasSensor,
  fasShieldAlt,
  fasShieldCheck,
  fasSignInAlt,
  fasSignOutAlt,
  fasSkull,
  fasSlidersH,
  fasStar,
  fasStarExclamation,
  fasStars,
  fasStopCircle,
  fasSuitcase,
  fasThumbtack,
  fasTimes,
  fasTimesCircle,
  fasTimesSquare,
  fasUniversalAccess,
  fasUser,
  fasUserCrown,
  fasUsers,
  fasWrench
);
