export const STORAGE_KEYS = {
  DIRECTORY: "turbot.login.directoryId",
  TAGS_FILTERS: "turbot.tags.filters",
  USER_ID: "turbot.login.userId",
};

export const tagsFiltersStorageKey = STORAGE_KEYS.TAGS_FILTERS;

export const getDirectoryStorageKey = () => {
  return STORAGE_KEYS.DIRECTORY;
};

export const getUserIdStorageKey = (directoryId) => {
  return `${STORAGE_KEYS.USER_ID}[${directoryId}]`;
};
