// Taken from https://github.com/goto-bus-stop/react-bus/
import React from "react";
import mitt from "mitt";

const BusContext = React.createContext(null);

export const useBus = () => {
  return React.useContext(BusContext);
};

export const useListener = (name, fn) => {
  const bus = useBus();
  React.useEffect(() => {
    bus.on(name, fn);
    return () => {
      bus.off(name, fn);
    };
  }, [bus, name, fn]);
};

export const withBus = (WrappedComponent) => {
  return (props) => {
    const bus = useBus();
    return <WrappedComponent {...props} bus={bus} />;
  };
};

export const Provider = ({ children }) => {
  const [bus] = React.useState(() => mitt());
  return <BusContext.Provider value={bus}>{children}</BusContext.Provider>;
};
