import { FilterReducer } from "./reducers/Filter";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import { AuthReducer } from "./reducers/Auth";
import { NotificationsReducer } from "./reducers/Notifications";
import { PermissionsReducer } from "./reducers/Permissions";
import { UserReducer } from "./reducers/User";

// Redux devtool
const composeEnhancers = composeWithDevTools({});

// TODO: later change to redux-observables
// This middleware makes it possible to make async actions like api calls etc
const asyncActionMiddleWare = (store) => (next) => (action) => {
  if (typeof action === "function") {
    // Pass getState so async actions have access to state
    action(next, store.getState);
  } else {
    next(action);
  }
};

const customMiddleWare = composeEnhancers(applyMiddleware(asyncActionMiddleWare));

const store = createStore(
  combineReducers({
    auth: AuthReducer,
    filters: FilterReducer,
    notifications: NotificationsReducer,
    permissions: PermissionsReducer,
    user: UserReducer,
  }),
  customMiddleWare
);

// console.log(store.getState());

export default store;
