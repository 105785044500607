export const ACTIONS = {
  CLEAR_USER_PERMISSIONS: "permissions_clear_user_permissions",
  SET_PERMISSIONS_METADATA: "permissions_set_metadata",
  SET_USER_PERMISSIONS: "permissions_set_user_permissions",
  SET_USER_PERMISSION_LOADING: "set_user_permissions_loading",
  SET_USER_ME_DATA: "set_user_me_data",
};

export const clearUserPermissions = () => {
  return {
    type: ACTIONS.CLEAR_USER_PERMISSIONS,
  };
};

export const setUserPermissions = (userPermissions) => {
  return {
    type: ACTIONS.SET_USER_PERMISSIONS,
    payload: userPermissions,
  };
};

export const setUserMe = (userMe) => {
  return {
    type: ACTIONS.SET_USER_ME_DATA,
    payload: userMe,
  };
};

export const setPermissionsMetadata = (permissionsMetadata) => {
  return {
    type: ACTIONS.SET_PERMISSIONS_METADATA,
    payload: permissionsMetadata,
  };
};

export const setUserPermissionsLoading = (inProgress) => {
  return { type: ACTIONS.SET_USER_PERMISSION_LOADING, payload: inProgress };
};
