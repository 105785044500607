import axios from "axios";
import store from "../store/AppStore";
import { createBrowserHistory } from "history";
import { isDebugMode } from "../utils/api";
import { logoutAsync } from "../store/actions/Auth";
import { selectLoggedIn } from "../store/selectors/User";
import { UI_URLS } from "../config/urls";

axios.defaults.headers.post["Content-Type"] = "application/json";

// @ts-check
class TurbotApi {
  constructor() {
    axios.defaults.headers.common["X-TURBOT-API-DEBUG"] = isDebugMode() ? "true" : "false";
  }

  static apiUrl = "/api/v5/";
  axios = axios;

  /**
   * Load all login methods
   * @returns {id,type,status,title,description}
   */
  async loadDirectories() {
    try {
      const resp = await this.axios.get(`${TurbotApi.apiUrl}directories`);
      return resp.data.items;
    } catch (error) {
      console.dir(error);
      if (error.response.status === 401) {
        // TODO: clear auth cookies, and refresh request.
        // await this.logoutAsync();
      } else {
        this.handleError(error);
      }
    }
  }

  /**
   * Default api call for login
   * @param {string} username
   * @param {string} password
   * @param {string} selectedDirectoryId
   * @returns {directory,profile,$token}
   */
  async localDirectoryLoginAsync(username, password, selectedDirectoryId) {
    try {
      const postData = {
        userId: username,
        $password: password,
      };
      const resp = await this.axios.post(
        `${TurbotApi.apiUrl}directories/${selectedDirectoryId}/local/callback?json=true`,
        postData
      );
      return resp.data;
    } catch (e) {
      this.handleError(e);
      throw e;
    }
  }

  /**
   * Load user profile
   */
  async loadUserProfile() {
    try {
      const resp = await this.axios.get(`${TurbotApi.apiUrl}profile`);
      return resp.data;
    } catch (e) {
      this.handleError(e);
      throw e;
    }
  }
  ƒ;
  /**
   * Refresh user profile / token
   */
  async refreshTokenAsync() {
    try {
      const resp = await this.axios.patch(`${TurbotApi.apiUrl}profile/tokens`);
      return resp.data;
    } catch (e) {
      this.handleError(e);
      throw e;
    }
  }

  /**
   * Destroy user token
   */
  async logoutAsync() {
    try {
      const resp = await this.axios.delete(`${TurbotApi.apiUrl}profile/tokens`);
      return resp.data;
    } catch (e) {
      this.handleError(e);
      throw e;
    }
  }

  /**
   * Test async calls
   * @param {number} mill
   */
  async tick(mill) {
    return new Promise((resolve) => setTimeout(resolve, mill));
  }

  handleError(e) {
    // TODO: register error
    console.error(e);
    if (e.response && e.response.status === 401) {
      const history = createBrowserHistory({ forceRefresh: true });
      const path = history.location.pathname;
      if (path !== UI_URLS.LOGIN) {
        const state = store.getState();
        if (selectLoggedIn(state)) {
          console.log("Logging user out...Axios");
          store.dispatch(logoutAsync());
          const history = createBrowserHistory({ forceRefresh: true });
          history.push(UI_URLS.LOGIN, { from: history.location });
        }
      }
    }
  }

  /**
   * Transforms key => value object to url query string.
   * @param {Object} params
   * @returns {string}
   */
  static generateSearchQuery(paramsAsObject) {
    const urlParams = new URLSearchParams();
    Object.keys(paramsAsObject).forEach((key) => {
      if (paramsAsObject[key] && paramsAsObject[key] !== "" && !urlParams.get(key)) {
        urlParams.set(key, paramsAsObject[key]);
      }
    });
    const query = urlParams.toString();
    return query !== "" ? `?${query}` : "";
  }
}

export default TurbotApi;
