import createReducer from "../utils/createReducer";
import { ACTIONS } from "../actions/Notifications";

const initialState = [];

const notificationsReducerLiteral = {
  [ACTIONS.ADD_NOTIFICATION]: (state, action) => [action.payload, ...state],
  [ACTIONS.REMOVE_NOTIFICATION]: (state, action) => {
    const notificationIndex = state.findIndex((notification) => notification.id === action.payload);
    const notificationsBefore = state.slice(0, notificationIndex);
    const notificationsAfter = state.slice(notificationIndex + 1, state.length);
    return [...notificationsBefore, ...notificationsAfter];
  },
  [ACTIONS.CLEAR_NOTIFICATIONS]: () => [],
};

export const NotificationsReducer = createReducer(notificationsReducerLiteral, initialState);
