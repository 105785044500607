import { useCallback, useState } from "react";

const useLocalStorage = (key) => {
  const [value, setValue] = useState(localStorage.getItem(key));
  const setItem = useCallback(
    (newValue) => {
      try {
        if (newValue) {
          localStorage.setItem(key, newValue);
        } else {
          localStorage.removeItem(key);
        }
        setValue(newValue);
      } catch (err) {
        console.error(`Error setting setting value for local storage key [${key}]`, err);
      }
    },
    [key]
  );
  return { setItem, value };
};

export default useLocalStorage;
