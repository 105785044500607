export const ACTIONS = {
  CLEAR_NOTIFICATIONS: "clear_notifications",
  ADD_NOTIFICATION: "add_notification",
  REMOVE_NOTIFICATION: "remove_notification",
};

export const clearNotifications = () => {
  return {
    type: ACTIONS.CLEAR_NOTIFICATIONS,
  };
};

export const addNotification = (notification) => {
  return {
    type: ACTIONS.ADD_NOTIFICATION,
    payload: notification,
  };
};

export const removeNotification = (id) => {
  return {
    type: ACTIONS.REMOVE_NOTIFICATION,
    payload: id,
  };
};
