import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import RedirectToApolloRoute from "./apollo/components/RedirectToApolloRoute";
import store from "./store/AppStore";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { loadProfileAsync } from "./store/actions/User";
import { Provider } from "react-redux";
import { Provider as BusProvider } from "./apollo/hooks/useBus";
import * as serviceWorker from "./serviceWorker";
import "./setupIcons";

const AppApollo = lazy(() => import("./apollo"));
const AppContainer = lazy(() => import("./AppContainer"));

store.dispatch(loadProfileAsync());

ReactDOM.render(
  <Provider store={store}>
    <BusProvider>
      <Router>
        <Suspense fallback={<></>}>
          <Switch>
            <Route path="/apollo">
              <AppApollo />
            </Route>
            <RedirectToApolloRoute>
              <AppContainer />
            </RedirectToApolloRoute>
          </Switch>
        </Suspense>
      </Router>
    </BusProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
